.facNMajorUnivDetail .slick-slide > div {
  margin: 0px 21.33px 0px 0px;
}

.commonUnivDetailSectionGap .slick-slide > div {
  margin: 0px 21.33px 0px 0px;
}

.admissionUnivDetail .slick-slide > div {
  margin: 0px 16px 0px 0px;
}

.expKampusMostFav .slick-slide > div {
  margin: 0px 12px;
}

.expJurusanMostFav .slick-slide > div {
  margin: 0px 21.33px 0px 0px;
}